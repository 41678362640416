import * as ReconcillitionService from '../services/ReconcillitionService';
import React from 'react';
import { toast } from "react-toastify";

export const RECONCILLITION_DATA_GET = 'RECONCILLITION_DATA_GET';
export const UPDATE_RECONCILLITION = 'UPDATE_RECONCILLITION';
export const UPDATE_DEPRECIATIONDATE = 'UPDATE_DEPRECIATIONDATE';
export const ACCOUNT_INACTIVE = 'ACCOUNT_INACTIVE';
export const RELOAD = 'RELOAD';
export const COMMONUPDATE = "COMMONUPDATE";
export const GetGeneralReportNotesData = "GetGeneralReportNotesData";
export const UpdateGeneralReportNotes = "UpdateGeneralReportNotes";
export const UPDATE_RECONCILLITION_CUSTOMFIELD = 'UPDATE_RECONCILLITION_CUSTOMFIELD';
export const GET_ACCOUNT_RECONSILIATION_REVIEWD_HISTORY = 'GET_ACCOUNT_RECONSILIATION_REVIEWD_HISTORY';

export const GET_AccountReconciliations = (CompanyId,isExlcuded) => {
    return (dispatch) => {
        ReconcillitionService.QuickbookAccount_AccountReconciliations(CompanyId,isExlcuded)
            .then((response) => {
                dispatch({ type: RECONCILLITION_DATA_GET, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const MarkAccountReconciliationReview = (companyId) => {
    return (dispatch) => {
        ReconcillitionService.MarkAccountReconciliationReview(companyId)
            .then((response) => {
                dispatch({ type: COMMONUPDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const GetAccountReconciliationReviewedHistory = (companyId) => {
    return (dispatch) => {
        ReconcillitionService.GetAccountReconciliationReviewedHistory(companyId)
            .then((response) => {
                dispatch({ type: GET_ACCOUNT_RECONSILIATION_REVIEWD_HISTORY, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const POST_updatecustomfield = (data) => {
    return (dispatch) => {
        ReconcillitionService.QuickbookAccount_updatecustomfield(data)
            .then((response) => {
                dispatch({ type: UPDATE_RECONCILLITION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const Embebded_POST_updatecustomfield = (data) => {
    return (dispatch) => {
        ReconcillitionService.QuickbookAccount_updatecustomfield(data)
            .then((response) => {
                dispatch({ type: UPDATE_RECONCILLITION, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const UpdateDepreciationDate = (companyId,depreciationUpdatedDate) => {
    return (dispatch) => {
        ReconcillitionService.QuickbookAccount_UpdateDepreciationDate(companyId,depreciationUpdatedDate)
            .then((response) => {
                dispatch({ type: UPDATE_DEPRECIATIONDATE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const PUT_QuickbookAccount_UpdateAccountStatus = (data) => {
    return (dispatch) => {
        ReconcillitionService.QuickbookAccount_UpdateAccountStatus(data)
            .then((response) => {
                dispatch({ type: ACCOUNT_INACTIVE, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const AddExcludedAccountReconciliationQuestionsAndComments = (data) => {
    return (dispatch) => {
        ReconcillitionService.AddExcludedAccountReconciliationQuestionsAndComments(data)
            .then((response) => {
                dispatch({ type: UPDATE_RECONCILLITION_CUSTOMFIELD, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`);
            })
    }
}

export const GetGeneralReportNotes = (CompanyID,ReportType) => {
    return (dispatch) => {
        ReconcillitionService.GetGeneralReportNotes(CompanyID,ReportType)
            .then((response) => {
                dispatch({ type: GetGeneralReportNotesData, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}

export const InsertUpdateGeneralReportNotes= (data) => {
    return (dispatch) => {
        ReconcillitionService.InsertUpdateGeneralReportNotes(data)
            .then((response) => {
                dispatch({ type: UpdateGeneralReportNotes, payload: response });
            })
            .catch((err) => {
                toast.error(`${err.response}`)
            })
    }
}
import baseService from './baseService';

export function ReportData(data) {
    return baseService.post(`/CompanyDashboard/ReportDetailList`, data);
}

export function EmployeePayrollReport(data)
{
    return baseService.post('/CompanyDashboard/PayrollEmployeeReportDetailList',data);
}

export function ReportData_BES(data) {
    return baseService.post(`/CompanyDashboard/ReportDetailList_BES`, data);
}

export function CompanyDashboard_PayrollEmployeeReportDetailList(data) {
    return baseService.post(`/CompanyDashboard/PayrollEmployeeReportDetailList`, data);
}

export function GetHAMemo(data){
    return baseService.post("/CompanyDashboard/PayrollEmployeeReportDetailList",data)
}

export function IncludeHAMemo(data) {
    return baseService.post("/CompanyDashboard/IncludeInHousingAllowance",data)
}

export function CompanyDashboard_PayrollEmployeeReportDetailList_BES(data) {
    return baseService.post(`/CompanyDashboard/PayrollEmployeeReportDetailList_BES`, data);
}

export function CompanyDashboard_PayrollVendorReportDetailList(data) {
    return baseService.post(`/CompanyDashboard/PayrollVendorReportDetailList`, data);
}

export function CompanyDashboard_PayrollVendorReportDetailList_BES(data) {
    return baseService.post(`/CompanyDashboard/PayrollVendorReportDetailList_BES`, data);
}

export function Vendor_UpdateVendorStatus(data) {
    return baseService.put(`/Vendor/UpdateVendorStatus`, data);
}

export function CompanyDashboard_UpdateVendorW9(data) {
    return baseService.post('/CompanyDashboard/UpdateVendorW9', data);
}

export function CompanyDashboard_UpdateVendorW9_BES(data) {
    return baseService.post('/CompanyDashboard/UpdateVendorW9_BES', data);
}

export function UpdateAccountDescription(data) {
    return baseService.post('/CompanyDashboard/UpdateAccountDescription', data);
}

export function UpdateDescription(data) {
    return baseService.post('/CompanyDashboard/UpdateDescription', data);
}

export function UpdateDescription_BES(data) {
    return baseService.post('/CompanyDashboard/UpdateDescription_BES', data);
}


export function UpdateAccountApprove_BES(data) {
    return baseService.post("/CompanyDashboard/UpdateAccountApprove_BES", data);
}


export function MarkAsResolved(data) {
    return baseService.post("/CompanyDashboard/MarkAsResolve", data);
}
export function UpdateAccountApprove(data) {
    return baseService.post("/CompanyDashboard/UpdateAccountApprove", data);
}

export function MarkAsResolved_BES(data) {
    return baseService.post("/CompanyDashboard/MarkAsResolve_BES", data);
}


export function MarkAsIgnored(data) {
    return baseService.post("/CompanyDashboard/MarkAsIgnore", data)
}

export function RevertIgnored(data) {
    return baseService.post("/CompanyDashboard/RevertMarkAsUnIgnore", data)
}

export function MarkAsIgnored_BES(data) {
    return baseService.post("/CompanyDashboard/MarkAsIgnore_BES", data)
}

export function RevertIgnored_BES(data) {
    return baseService.post("/CompanyDashboard/RevertMarkAsUnIgnore_BES", data)
}



export function MarkAsIgnoreForVendor(data) {
    return baseService.post("/CompanyDashboard/MarkAsIgnoreForVendor", data)
}

export function UpdateEmployeeW4Status(data) {
    return baseService.post("/CompanyDashboard/UpdateEmployeeW4Status", data);
}

export function UpdateEmployeeW4Status_BES(data) {
    return baseService.post("/CompanyDashboard/UpdateEmployeeW4Status_BES", data);
}

export function UpdateAccountantComments(data) {
    return baseService.post("/CompanyDashboard/UpdateAccountantComment", data);
}

export function MarkAsCPAApproved(data)
{
    return baseService.post("/CompanyDashboard/MarkAsCPAApproved",data);
}

export function ApproveWorkerClassifiedAsAnEmployee(data)
{
    return baseService.post("/Vendor1099Report/ApproveWorkerClassifiedAsAnEmployee",data);
}

export function ApproveWorkerClassifiedAsAnEmployee_BES(data)
{
    return baseService.post("/Vendor1099Report/ApproveWorkerClassifiedAsAnEmployee_BES",data);
}

export function MarkASReviewedReport(data)
{
    return baseService.post("/CompanyDashboard/MarkASReviewedReport",data)
}

export function MarkASReviewedReport_BES(data)
{
    return baseService.post("/CompanyDashboard/MarkASReviewedReport_BES",data)
}


export function GetReportReviewData(data)
{
    return baseService.get("/CompanyDashboard/GetReportRevieweds?CompanyId="+data.companyId+"&reportType="+data.ReportType)
}

export function GetReportReviewData_BES(data)
{
    return baseService.get("/CompanyDashboard/GetReportRevieweds_BES?CompanyId="+data.companyId+"&reportType="+data.ReportType)
}

export function GetTFBCategories() {
    return baseService.get("/CompanyDashboard/GetBenefitType");
}

export function AssignTFBToEmployee(data){
    return baseService.post("/CompanyDashboard/AssignToEmployee",data)
}

export function AssignTFBToEmployee_BES(data){
    return baseService.post("/CompanyDashboard/AssignToEmployee_BES",data)
}

export function AssignBatchTFBToCategory(data){
    return baseService.post("/CompanyDashboard/AssignBatchTFBToCategory",data)
}

export function AssignBatchTFBToCategory_BES(data){
    return baseService.post("/CompanyDashboard/AssignBatchTFBToCategory_BES",data)
}

export function AssignTFBToCategory(data) {
    return baseService.post("/CompanyDashboard/AssignToTFBCategory",data)
}

export function AssignTFBToCategoryWithEmployee(data) {
    return baseService.post("/CompanyDashboard/AssignToEmployeeTFBCategory",data)
}
export function GetRecordTFBInPayroll(data)
{
    return baseService.post("/CompanyDashboard/GetRecordTaxableFringeBenefitInPayrollSystem",data)
}

export function MarkTFBRecordedInPayrollSystem(data)
{
    return baseService.post("/CompanyDashboard/TFBRecordedInPayrollSystem",data)
}

export function MarkAccountantApproval(data)
{
    return baseService.post("/Vendor1099Report/UpdateVendor1099ApprovedByAccountant",data)
}

export function MarkClientApproval(data)
{
    return baseService.post("/Vendor1099Report/UpdateVendor1099ApprovedByClient",data)
}

export function Vendor1099Exported(data)
{
    return baseService.post("/CompanyDashboard/Vendor1099ReportExported",data);
}


export function Vendor1099Mailed(data)
{
    return baseService.post("/CompanyDashboard/Vendor1099ReportMailed",data);
}

export function Vendor1099Mailed_BES(data)
{
    return baseService.post("/CompanyDashboard/Vendor1099ReportMailed_BES",data);
}

export function AccountReconciliationReviewSummery(companyid){
    return baseService.get("/QuickbookAccount/GetAccountReconciliationReviewSummery?CompanyID="+companyid);
}

export function ACCFinancialCheckup(data)
{
    return baseService.post("/QuickbookAccount/GetClientsReadyForFinancialCheckupStatus",data);
}
export function CompanyTaxInformation(data) {
    return baseService.post("/QuickbookAccount/GetCompaniesTaxInformation",data);
}
export function ServicePlanLevels(data)
{
    return baseService.post("/QuickbookAccount/GetServicePlanLevels",data);
}

export function GetExcludedAccRecCompany(pageno,size,IsIncludeReportData=false)
{
    return baseService.get("/QuickbookAccount/GetExcludedAccountReconciliationCompany?pageNumber="+pageno+"&pageSize="+size+"&IsIncludeReportData="+IsIncludeReportData);
}

export function UpdateVendorNamesDiscrepancy(data){
    return baseService.post('/Vendor1099Report/UpdateVendorNamesDiscrepancy',data);
}

export function UpdateVendorName(data){
    return baseService.post('/Vendor1099Report/UpdateVendorName',data);
}

export function GetRTCData(data){
    return baseService.post("/Comment/getReviewTransactionCommentsDetails",data);

}

export function MarkAsIgnoreResolveExcessHousingAllowanceTxn(data) {
    return baseService.post("/CompanyDashboard/MarkAsIgnoreResolveExcessHousingAllowanceTxn", data)
}

export function RevertMarkAsUnIgnoreResolveExcessHousingAllowanceTxn(data) {
    return baseService.post("/CompanyDashboard/RevertMarkAsUnIgnoreResolveExcessHousingAllowanceTxn", data)
}

export function UpdateVendorDoNotIssue1099(data)
{
    return baseService.post("/Vendor1099Report/UpdateVendorDoNotIssue1099",data)
}

export function UpdateVendorDoNotIssue1099_BES(data)
{
    return baseService.post("/Vendor1099Report/UpdateVendorDoNotIssue1099_BES",data)
}


export function UpdateTransactionCheckNumber(data)
{
    return baseService.post("/CompanyDashboard/UpdateTransactionCheckNumber",data)
}

export function UpdateTransactionCheckNumber_BES(data)
{
    return baseService.post("/CompanyDashboard/UpdateTransactionCheckNumber_BES",data)
}
export const dropdownValueMapping = {
    '1': 'Our payroll service provider will issue 1099s',
    '2': 'The payments to this vendor is not for compensation',
    '3': 'We do not want to report compensation to this vendor',
    '4': 'Vendor Processes Payments Electronically',
    '5': 'We have recently requested a W-9 from vendor',
    '6': 'This vendor refuses to complete W-9',
    '7': 'We requested a W-9 from the vendor at least twice more than 30 days ago and we have not received a response from the vendor',
    '8': 'We have no contact information for this vendor',
  };

  export const entityToVendorMapping = {
    1: 0,
    2: 9,
    3: 9,
    4: 0,
    5: 0,
    6: 10,
    8: 10,
    9: 0,
    10: 12,
    11: 0,
};

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { updateVendor, updateCustomFileds} from '../appRedux/actions/vendorAction';
import { findIndex } from "lodash";
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import _ from 'lodash';
import './form.css';
import { toast } from 'react-toastify';
import { CheckTaxFormat } from "../app/common/commonServices";

class VendorForm extends React.Component {

    constructor() {
        super()
        this.state = {
            vendorID: '',
            companyID: '',
            listID: '',
            name: '',
            bill1: '',
            isActive: true,
            accountNumber: '',
            mainEmail: '',
            mainPhone: '',
            vendor1099: true,
            familyName: '',
            companyName: '',
            displayName: '',
            printOnCheckName: '',
            vendorTypeId: '',
            entityTypeId: '',
            contractorAssessmentStatus: "",
            businessName: '',
            excludeMonitoring: true,
            entityOther: '',
            vendor1099ApprovedByAccountant: false,
            vendor1099ApprovedByClient: true,
            vendor1099Comments: '',
            ShowTaxMessage: false,
            isEmployeeStatusApprovedbyClient: false,
            middleName: "",
            suffix: "",
            title: "",
            doNotIssue1099: ""
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    handleCancel = () => {
        this.props.handleCancel()
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.visible !== nextProps.visible) {
            this.setState({
                vendorID: nextProps.selectedItem.vendorID,
                companyID: nextProps.selectedItem.companyID,
                listID: nextProps.selectedItem.listID,
                name: nextProps.selectedItem.name,
                bill1: nextProps.selectedItem.bill1,
                bill3: nextProps.selectedItem.bill3,
                bill4: nextProps.selectedItem.bill4,
                bill5: nextProps.selectedItem.bill5,
                taxIdentifier: nextProps.selectedItem.taxIdentifier,
                isActive: nextProps.selectedItem.isActive,
                accountNumber: nextProps.selectedItem.accountNumber,
                mainEmail: nextProps.selectedItem.mainEmail,
                mainPhone: nextProps.selectedItem.mainPhone,
                vendor1099: nextProps.selectedItem.vendor1099,
                familyName: nextProps.selectedItem.familyName,
                companyName: nextProps.selectedItem.companyName,
                displayName: nextProps.selectedItem.displayName,
                printOnCheckName: nextProps.selectedItem.printOnCheckName,
                vendorTypeId: nextProps.selectedItem.vendorTypeId,
                entityTypeId: nextProps.selectedItem.entityTypeId,
                contractorAssessmentStatus: nextProps.selectedItem.contractorAssessmentStatus,
                businessName: nextProps.selectedItem.businessName,
                excludeMonitoring: nextProps.selectedItem.excludeMonitoring,
                entityOther: nextProps.selectedItem.entityOther,
                vendor1099ApprovedByAccountant: nextProps.selectedItem.vendor1099ApprovedByAccountant,
                vendor1099ApprovedByClient: nextProps.selectedItem.vendor1099ApprovedByClient,
                vendor1099Comments: nextProps.selectedItem.vendor1099Comments,
                ShowTaxMessage: false,
                suffix: nextProps.selectedItem.suffix,
                title: nextProps.selectedItem.title,
                middleName: nextProps.selectedItem.middleName,
                isEmployeeStatusApprovedbyClient: nextProps.selectedItem.isEmployeeStatusApprovedbyClient,
                doNotIssue1099: nextProps.selectedItem.doNotIssue1099
            });
        }
    }

    handleSubmit = (e) => {

        let tax = this.state.taxIdentifier;
        if (tax != null && tax.replace(/\\/g, '/') !== "") {
            if (CheckTaxFormat(tax)) {
                e.preventDefault();
                this.setState({ ShowTaxMessage: true });
                return false;
            }
        }

        e.preventDefault();
        let data = {
            vendorID: this.state.vendorID,
            companyID: this.state.companyID,
            listID: this.state.listID,
            name: this.state.name,
            bill1: this.state.bill1,
            bill3: this.state.bill3,
            bill4: this.state.bill4,
            bill5: this.state.bill5,
            taxIdentifier: this.state.taxIdentifier,
            isActive: this.state.isActive,
            accountNumber: this.state.accountNumber,
            mainEmail: this.state.mainEmail,
            mainPhone: this.state.mainPhone,
            vendor1099: this.state.vendor1099,
            familyName: this.state.familyName,
            companyName: this.state.companyName,
            displayName: this.state.displayName,
            printOnCheckName: this.state.printOnCheckName,
            vendorTypeId: Number(this.state.vendorTypeId),
            entityTypeId: Number(this.state.entityTypeId),
            contractorAssessmentStatus: this.state.contractorAssessmentStatus,
            businessName: this.state.businessName,
            excludeMonitoring: this.state.excludeMonitoring,
            vendor1099ApprovedByAccountant: this.state.vendor1099ApprovedByAccountant,
            vendor1099ApprovedByClient: this.state.vendor1099ApprovedByClient,
            vendor1099Comments: this.state.vendor1099Comments,
            isEmployeeStatusApprovedbyClient: this.state.isEmployeeStatusApprovedbyClient,
            middleName: this.state.middleName,
            title: this.state.title,
            suffix: this.state.suffix,
            doNotIssue1099: this.state.doNotIssue1099

        };

        let data1 = {
            vendorId: this.state.vendorID,
            vendorTypeId: Number(this.state.vendorTypeId),
            entityTypeId: Number(this.state.entityTypeId),
            contractorAssessmentStatus: this.state.contractorAssessmentStatus,
            businessName: this.state.businessName,
            excludeMonitoring: this.state.excludeMonitoring,
            entityOther: this.state.entityOther,
            vendor1099: this.state.vendor1099,
            name: this.state.name,
            accountNumber: this.state.accountNumber,
            vendor1099ApprovedByAccountant: this.state.vendor1099ApprovedByAccountant,
            vendor1099ApprovedByClient: this.state.vendor1099ApprovedByClient,
            vendor1099Comments: this.state.vendor1099Comments,
            doNotIssue1099: this.state.doNotIssue1099
        }
        this.props.updateVendor(data);
        this.props.updateCustomFileds(data1);
        this.props.handleSave(false);
    }

    handleFormChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleFormTypeChange = (event) => {
        const { name, value } = event.target;
        debugger;
        if (name === 'entityTypeId') {
            const { entityTypeData, vendorTypeData } = this.props;
            const selectedEntity = _.find(entityTypeData, { entityId: parseInt(value) });

            if (selectedEntity) {
                const vendorTypeId = selectedEntity.vendorTypeId;
                const mappedVendor = _.find(vendorTypeData, { vendorTypeId });
                this.setState({
                    [name]: value,
                    vendorTypeId: mappedVendor ? mappedVendor.vendorTypeId : '',
                });
            } else {
                console.error('Entity not found for the given entityTypeId:', value);
                this.setState({
                    [name]: value,
                    vendorTypeId: '',
                });
            }
        } else {
            this.setState({
                [name]: value,
            });
        }
    };

    handleFormChecked = name => event => {

        this.setState({ [name]: event.target.checked });
    };

    render() {
        const { visible, isEdit, entityTypeData, vendorTypeData, doNotIssueDropdownOptions, selectedItem } = this.props;
        var entName = "";
        if (this.state.entityTypeId != '') {
            var entObj = _.find(entityTypeData, { entityId: eval(this.state.entityTypeId) });
            entName = entObj.name;
        }
        return (
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={visible}
                onClose={this.handleCancel}
            >
                <form method="post" onSubmit={this.handleSubmit} autoComplete="off" style={{ padding: 20 }}>
                    <DialogTitle>Edit Vendor</DialogTitle>
                    <DialogContent>
                        <fieldset>
                            <Grid container spacing={40} >
                                <Grid item sm={1} >
                                    <TextField
                                        name="title"
                                        label="Title"
                                        fullWidth
                                        // required={true}
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.title}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="name"
                                        label="First Name"
                                        fullWidth
                                        // required={true}
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.name}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="middleName"
                                        label="Middle Name"
                                        fullWidth
                                        // required={true}
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.middleName}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="familyName"
                                        label={"Last Name"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.familyName}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={2} >
                                    <TextField
                                        name="suffix"
                                        label="Suffix"
                                        fullWidth
                                        // required={true}
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.suffix}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={40} >
                                <Grid item sm={3} >
                                    <TextField
                                        name="companyName"
                                        label={"Company Name"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.companyName}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="displayName"
                                        label={"Display Name"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.displayName}
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item sm={3} >
                                    <TextField
                                        name="bill1"
                                        label="Address"
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.bill1}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="bill3"
                                        label="City"
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.bill3}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="bill4"
                                        label="State"
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.bill4}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="bill5"
                                        label="Zip Code"
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.bill5}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} >
                                    <TextField
                                        name="taxIdentifier"
                                        label="Tax Identifier"
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.taxIdentifier}
                                        margin="normal"
                                    />
                                    {this.state.ShowTaxMessage && <div style={{ color: "red" }}>Please enter tax identifier in below format
                                        <ul>
                                            <li>99-9999999</li>
                                            <li>999-99-9999 </li>
                                            <li>XXXXXX9999 </li>
                                            <li>XXXXXXX9999 </li>
                                        </ul> </div>}
                                </Grid>
                                <Grid item sm={3}>
                                    <TextField
                                        name="accountNumber"
                                        // required={true}
                                        label={"Account Number"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.accountNumber}
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item sm={3} >
                                    <TextField
                                        name="mainEmail"
                                        type="email"
                                        label={"Email"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.mainEmail}
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item sm={3} style={{ display: 'none' }}>
                                    <TextField
                                        name="mainPhone"
                                        label={"Phone"}
                                        fullWidth
                                        type="Number"
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.mainPhone}
                                        margin="normal"
                                    />
                                </Grid>

                            </Grid>
                            <Grid container spacing={40} >

                                <Grid item sm={4}>
                                    <InputLabel className="labletext">W-9 Entity Type</InputLabel>
                                    <Select
                                        name="entityTypeId"
                                        native
                                        value={this.state.entityTypeId}
                                        onChange={this.handleFormTypeChange}
                                        inputProps={{
                                            name: 'entityTypeId',
                                            id: 'account-native-simple',
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <option value={''}>Select W-9 Entity Type</option>
                                        {entityTypeData.length && entityTypeData.map((value, index) => (
                                            <option value={value.entityId} key={index}>{value.name}</option>
                                        ))}
                                    </Select>
                                    {entName == 'Other' &&
                                        <TextField
                                            name="entityOther"
                                            fullWidth
                                            onChange={this.handleFormChange}
                                            defaultValue={this.state.entityOther}
                                            margin="normal"
                                        />}
                                </Grid>
                                <Grid item sm={4}>
                                    <InputLabel className="labletext">Compensation Type</InputLabel>
                                    <Select
                                        name="vendorTypeId"
                                        native
                                        value={this.state.vendorTypeId}
                                        onChange={this.handleFormTypeChange}
                                        inputProps={{
                                            name: 'vendorTypeId',
                                            id: 'account-native-simple',
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <option value={''}>Select Vendor Type</option>
                                        {vendorTypeData.length && vendorTypeData.map((value, index) => (
                                            <option value={value.vendorTypeId} key={index}>{value.name}</option>
                                        ))}
                                    </Select>

                                </Grid>
                                <Grid item sm={4}>
                                    <InputLabel className="labletext">W-9/1099 Reporting Issues</InputLabel>
                                    <Select
                                        name="doNotIssue1099"
                                        native
                                        value={this.state.doNotIssue1099}
                                        onChange={this.handleFormChange}
                                        inputProps={{
                                            name: 'doNotIssue1099',
                                            id: 'account-native-simple',
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <option value={''}>Select W-9/1099 Reporting Issues</option>
                                        {doNotIssueDropdownOptions.length && doNotIssueDropdownOptions.map((value, index) => (
                                            <option value={value.id} key={index}>{value.description}</option>
                                        ))}
                                    </Select>
                                </Grid>

                                <Grid item sm={4}>

                                    <InputLabel className="labletext">Contractor Assessment Status</InputLabel>
                                    <Select
                                        name="contractorAssessmentStatus"
                                        native

                                        value={this.state.contractorAssessmentStatus}
                                        onChange={this.handleFormChange}
                                        inputProps={{
                                            name: 'contractorAssessmentStatus',
                                            id: 'account-native-simple',
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <option value={''}>Select Contractor Assessment Status</option>
                                        <option value={'Pending'}>Pending</option>
                                        <option value={'Completed'}>Completed</option>
                                        <option value={'Not required'}>Not required</option>

                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container spacing={40} >

                                <Grid item sm={3} >
                                    <TextField
                                        name="printOnCheckName"
                                        label={"Print On Check Name"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.printOnCheckName}
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item sm={3} >
                                    <TextField
                                        name="businessName"
                                        label={"Business Name"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.businessName}
                                        margin="normal"
                                    />
                                </Grid>

                                <Grid item sm={3} style={{ 'paddingTop': '45px' }} >
                                    {
                                        this.state.doNotIssue1099 ?
                                            <Checkbox
                                                onChange={this.handleFormChecked('vendor1099')}
                                                checked={this.state.vendor1099}
                                                disabled />
                                            :
                                            <Checkbox
                                                onChange={this.handleFormChecked('vendor1099')}
                                                checked={this.state.vendor1099}
                                            />
                                    }

                                    <label>1099 Vendor</label>
                                </Grid>

                                <Grid item sm={3} style={{ 'paddingTop': '45px' }}  >
                                    <Checkbox
                                        onChange={this.handleFormChecked('excludeMonitoring')}
                                        checked={this.state.excludeMonitoring}
                                    />
                                    <label>Stop Monitoring</label>
                                </Grid>
                            </Grid>
                            <Grid container spacing={40}>
                                <Grid item sm={3} style={{ 'paddingTop': '45px' }}  >
                                    <Checkbox
                                        onChange={this.handleFormChecked('vendor1099ApprovedByAccountant')}
                                        checked={this.state.vendor1099ApprovedByAccountant}
                                    />
                                    <label>1099 Approved by Accountant</label>
                                </Grid>
                                <Grid item sm={3} style={{ 'paddingTop': '45px' }}  >
                                    <Checkbox
                                        onChange={this.handleFormChecked('vendor1099ApprovedByClient')}
                                        checked={this.state.vendor1099ApprovedByClient}
                                    />
                                    <label>1099 Approved by Client</label>
                                </Grid>

                                <Grid item sm={6} style={{ 'paddingTop': '45px' }}  >
                                    <TextField
                                        name="vendor1099Comments"
                                        label={"Comment"}
                                        fullWidth
                                        onChange={this.handleFormChange}
                                        defaultValue={this.state.vendor1099Comments}
                                        margin="normal"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={40}>
                                <Grid item sm={3} style={{ 'paddingTop': '45px' }}>
                                    <Checkbox
                                        onChange={this.handleFormChecked('isEmployeeStatusApprovedbyClient')}
                                        checked={this.state.isEmployeeStatusApprovedbyClient}
                                    />
                                    <label>Employee Status Approved By Client</label>
                                </Grid>
                            </Grid>
                        </fieldset>

                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" color="primary">
                            Update
                        </Button>
                        <Button onClick={this.handleCancel} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        updateVendor,
        updateCustomFileds
    }, dispatch)
}

export default connect(null, mapDispatchToProps)(VendorForm);




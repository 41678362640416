import {
    RECONCILLITION_DATA_GET,
    UPDATE_RECONCILLITION,
    ACCOUNT_INACTIVE,
    RELOAD,
    UPDATE_RECONCILLITION_CUSTOMFIELD,
    COMMONUPDATE, GET_ACCOUNT_RECONSILIATION_REVIEWD_HISTORY,
    GetGeneralReportNotesData,
    UpdateGeneralReportNotes,
    UPDATE_DEPRECIATIONDATE
} from '../actions/ReconcillitionAction';
const initial_state = {
    reconcillition: null,
    Update_reconciled: null,
    account_Inactive: null,
    Update_reconciled_CustomField: null,
    Update_depreciation: null,
    ReconciliedAccountHistory: null,
    GetGeneralReportNotesData: null
};
export default function reconcillitionReducer(state = initial_state, action) {
    switch (action.type) {
        case RECONCILLITION_DATA_GET:
            return { ...state, reconcillition: action.payload.data };
        case UPDATE_RECONCILLITION:
            return { ...state, Update_reconciled: action.payload.data };
        case UPDATE_DEPRECIATIONDATE:
            return { ...state, Update_depreciation: action.payload.data };
        case GetGeneralReportNotesData:
            return { ...state, GetGeneralReportNotesData: action.payload.data };
        case UpdateGeneralReportNotes:
            return { ...state, UpdateGeneralReportNotes: action.payload.data };
        case GET_ACCOUNT_RECONSILIATION_REVIEWD_HISTORY:
            return { ...state, ReconciliedAccountHistory: action.payload.data };
        case COMMONUPDATE:
            return { ...state, CommonUpdate: action.payload.data };
        case ACCOUNT_INACTIVE:
            return { ...state, account_Inactive: action.payload.data };
        case UPDATE_RECONCILLITION_CUSTOMFIELD:
            return { ...state, Update_reconciled_CustomField: action.payload.data };
        case RELOAD:
            return { ...state, reload: true };
        default:
            return state;
    }
}

import React, { Component } from "react";
import IntlMessages from "../../../../util/IntlMessages";
import { Vendors, searchVendor, vendorTypes, entitytypes, GetVendors, doNotIssueDeopdownValues } from '../../../../appRedux/actions/vendorAction';
import { Post_Vendor_UpdateVendorStatus } from '../../../../appRedux/actions/ReportDetailAction';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";
import CircularProgress from "../../../../components/CircularProgress";
import VendorForm from '../../../../Forms/VendorForm.js';
import SearchBox from '../../../../components/SearchBox';
import { MaskTaxIdentifier, ModuleType } from "../../../common/commonServices";
import "react-toastify/dist/ReactToastify.css";
import { Table, Pagination } from 'antd';
import Tooltip from '@material-ui/core/Tooltip';
import { Resizable } from 'react-resizable';
import "../../styles.css";
import HistoryComponent from "../../../common/History";
import Switch from '@material-ui/core/Switch';
import { toast } from 'react-toastify';
import { dropdownValueMapping } from '../../../common/1099ReportingDataMapping'

const classes = {
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: 15,
  },
};
const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable width={width} height={0} onResize={onResize}>
      <th {...restProps} />
    </Resizable>
  );
};

function arrowGenerator(color) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  };
}

const styles = theme => ({
  button: {
    margin: 0
  },
  lightTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrowPopper: arrowGenerator(theme.palette.grey[700]),
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  bootstrapPopper: arrowGenerator(theme.palette.common.black),
  bootstrapTooltip: {
    backgroundColor: theme.palette.common.black,
  },
  bootstrapPlacementLeft: {
    margin: '0 8px',
  },
  bootstrapPlacementRight: {
    margin: '0 8px',
  },
  bootstrapPlacementTop: {
    margin: '8px 0',
  },
  bootstrapPlacementBottom: {
    margin: '8px 0',
  },
  htmlPopper: arrowGenerator('#dadde9'),
  htmlTooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    '& b': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  }
});
class VendorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    let column = [{
      title: 'First Name',
      dataIndex: 'name',
      className: 'table-heading',
      sorter: true,
      width: 200

    }, {
      title: 'Last Name',
      dataIndex: 'familyName',
      className: 'table-heading',
      sorter: true,
      width: 200

    }, {
      title: 'Company Name',
      dataIndex: 'companyName',
      className: 'table-heading',
      sorter: true,
      width: 150
    }, {
      title: 'Display Name',
      dataIndex: 'displayName',
      className: 'table-heading',
      sorter: true,
      width: 200
    }, {
      title: 'Tax Identifier',
      dataIndex: 'MaskTax',
      className: 'table-heading',
      sorter: false,
      width: 150,
      // render: text => MaskTaxIdentifier(text)

    }, {
      title: 'Compensation Type',
      dataIndex: 'vendorType',
      className: 'table-heading',
      sorter: true,
      width: 150
    }, {
      title: 'W-9 Entity Type',
      dataIndex: 'entityType',
      className: 'table-heading',
      sorter: true,
      width: 150
    },{
      title: 'W-9/1099 Reporting Issues',
      dataIndex: 'doNotIssue1099',
      className: 'table-heading',
      sorter: true,
      width: 150,
      render: (doNotIssue1099) => dropdownValueMapping[`${doNotIssue1099}`] || ''
    }, {
      title: '1099 Vendor',
      dataIndex: 'vendor1099',
      className: 'table-heading',
      sorter: true,
      width: 120,
      render: text => <span>
        {text ? "Yes" : ""}
      </span>,
    }, {
      title: <div>
        {this.state ? <Tooltip
          classes={{
            popper: classes.htmlPopper,
            tooltip: classes.htmlTooltip,
          }}
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: Boolean(this.state.arrowRef),
                  element: this.state.arrowRef,
                },
              },
            },
          }}
          title={
            <React.Fragment>
              <div style={{ fontSize: 13, fontFamily: "Roboto" }}>Do not monitor this vendor for 1099 reporting.</div>
            </React.Fragment>
          }
        >
          <span style={{ 'cursor': 'pointer' }}>Stop Monitoring</span>
        </Tooltip> : 'Stop Monitoring'}

      </div>,
      dataIndex: 'excludeMonitoring',
      className: 'table-heading',
      sorter: true,
      width: 140,
      render: text => <div>
        {text ? <Tooltip
          classes={{
            popper: classes.htmlPopper,
            tooltip: classes.htmlTooltip,
          }}
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: Boolean(this.state.arrowRef),
                  element: this.state.arrowRef,
                },
              },
            },
          }}
          title={
            <React.Fragment>
              <div style={{ fontSize: 13, fontFamily: "Roboto" }}>Do not monitor this vendor for 1099 reporting.</div>
            </React.Fragment>
          }
        >
          <span style={{ 'cursor': 'pointer' }}>Yes</span>
        </Tooltip> : ''}

      </div>
    }, {
      title: 'Assessment Status',
      dataIndex: 'contractorAssessmentStatus',
      className: 'table-heading',
      sorter: true

    }, {
      title: 'Status',
      dataIndex: 'isActive',
      className: 'table-heading',
      sorter: false,
      width: 200,
      render: (text, record) => <div>
        <Switch className={text ? 'switch-box' : 'switch-box switch-inactive'} size="small" checked={text} onChange={(e) => this.vendorStatusChange(e, record)} /> <span className={text ? 'text-success' : ''}>{text ? "Active" : "Inactive"}</span>
      </div>
    },
    {
      title: <img src="/vendors/Forma 1.png" alt="" />,
      dataIndex: "vendorID",
      render: id => <img alt="" src="/vendors/Forma 1.png" onClick={(e) => this.LoadHistory(e, id)} />
    }
    ];

    this.state = {
      pageSize: 50,
      currentPage: 1,
      totalRowsInTable: 0,
      fieldName: 'vendorId',
      sortFormat: 'A',
      columns: column,
      loading: true,
      searchText: '',
      search: false,
      vendors: [],
      selectedVendor: '',
      isOpen: false,
      isAddOpen: false,
      isEdit: false,
      companyId: '',
      entityTypeData: [],
      vendorTypeData: [],
      doNotIssueDropdownOptions: [],
      recordId: 0,
      ShowHistory: false
    }
  }
  componentDidMount() {
    this.listData();
    this.props.vendorTypes();
    this.props.entitytypes();
    this.props.doNotIssueDeopdownValues();
  }
  vendorStatusChange = (e, item) => {
    e.stopPropagation();
    if (item.balance != 0 && !e.target.checked) {
      toast.error("This Vendor has a non-zero balance. you can not make as inactive.");
      return false;
    }
    var updateByNameObj = {
      "vendorID": item.vendorID,
      "companyID": item.companyID,
      "isActive": e.target.checked,
      "createdByName": null
    }
    this.setState({ loading: true }, () => {
      this.props.Post_Vendor_UpdateVendorStatus(updateByNameObj);
    });
  }
  listData() {
    let user = JSON.parse(localStorage.getItem('userData'));
    const CompanyId = user.companyId;
    this.setState({ companyId: user.companyId })
    this.props.GetVendors(CompanyId, this.state.searchText, this.state.currentPage, this.state.pageSize, this.state.fieldName, this.state.sortFormat);
  }

  LoadHistory = (e, id) => {
    e.stopPropagation();
    this.setState({ recordId: id, ShowHistory: true })
  }

  componentWillReceiveProps(nextProps) {

    try {
      if (nextProps.vendors !== this.props.vendors) {
        let total = 0;
        if (nextProps.vendors.length > 0) {
          //this.state.totalRowsInTable 
          total = nextProps.vendors[0].totalrows;
        }

        let item = nextProps.vendors;
        if (nextProps.vendors != null && nextProps.vendors.length > 0) {
          item.map((data, index) => {
            item[index].MaskTax = MaskTaxIdentifier(data.taxIdentifier)
          });
        }
        this.setState({ vendors: item, loading: false, totalRowsInTable: total });
      }
      if (nextProps.entityTypeData !== this.props.entityTypeData) {
        this.setState({ entityTypeData: nextProps.entityTypeData });
      }
      if (nextProps.vendorTypeData !== this.props.vendorTypeData) {

        this.setState({ loading: false, vendorTypeData: nextProps.vendorTypeData });
      }
      if(nextProps.doNotIssueDropdownOptions !== this.props.doNotIssueDropdownOptions){
        this.setState({ loading: false, doNotIssueDropdownOptions: nextProps.doNotIssueDropdownOptions.returnObject });
      }
      if (nextProps.reload == true) { this.listData(); }
      if (nextProps.activeInactive !== this.props.activeInactive) {
        this.setState({
          loading: true, isEdit: false
        }, () => {
          this.listData();
        });
      }
    }
    catch (err) {

    }

  }
  handleCancel = () => {
    this.setState({ isOpen: false, isEdit: false });
  };

  handleAddModel = () => {
    this.setState({ isAddOpen: true, isEdit: false });
  }

  handleEditModal = (record) => {
    var id = record.key;
    var data = this.props.vendors;
    if (id != 0) {
      const selectedVendor = _.find(data, { vendorID: id });
      this.setState({
        selectedVendor: selectedVendor,
        isEdit: true,
      });
    }

  }

  handleChange = (text) => {
    var self = this;
    if (text) {
      self.state.searchText = text;
    } else {
      self.state.searchText = '';
    }
    self.state.currentPage = 1;
    this.setState({ search: true });
    clearTimeout(self.state.timeout);
    // Make a new timeout set to go off in 800ms
    self.state.timeout = setTimeout(function () {
      self.listData();
    }, 500);

  }
  handleSave = (state) => {
    this.setState({
      isOpen: state,
      isEdit: state
    });
  }

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };
  handleTableChange = (pagination, filters, sorter) => {
    if (sorter.order) {
      this.state.fieldName = sorter.field;
      if (sorter.order == "ascend")
        this.state.sortFormat = "A";
      else
        this.state.sortFormat = "D";
    }
    else {
      this.state.fieldName = "vendorId";
      this.state.sortFormat = "A";
    }
    this.listData();
  }
  onShowSizeChange(current, pageSize) {
    this.state.currentPage = current;
    this.state.pageSize = pageSize;
    this.listData();
  }

  CloseHistory = () => {
    this.setState({ ShowHistory: false });
  }

  render() {

    let { searchText, vendors, search } = this.state;
    const columns = this.state.columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    }));
    const components = {
      header: {
        cell: ResizeableTitle,
      },
    };

    let filteredData = vendors.length && vendors.map((d, index) => ({
      key: d.vendorID,
      ...d
    }))
    return (
      <React.Fragment>
        {this.state.ShowHistory &&
          <HistoryComponent
            PreText={null}
            ModuleType={ModuleType.Vendors}
            RecordId={this.state.recordId}
            CompanyId={this.state.companyId}
            CloseHistory={this.CloseHistory}
          >
          </HistoryComponent>}

        {<div className="vendorsPage" style={{ padding: 15 }}>
          <Grid
            container
            spacing={24}
            style={{ 'marginBottom': 10 }}
          >
            <Grid item xs={12} sm={6}>
              <h3 className="user-title">Vendors</h3>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                container
                spacing={8}
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item  >
                  <SearchBox
                    styleName="d-none d-lg-block"
                    placeholder="Search... "
                    value={searchText}
                    onChange={(e) => { this.handleChange(e.target.value) }}
                    style={{ background: '#eeeeeeb8' }}
                  />
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          {this.state.loading == false ?
            <VendorForm
              isEdit={this.state.isEdit}
              handleSave={this.handleSave}
              visible={this.state.isEdit}
              entityTypeData={this.state.entityTypeData}
              vendorTypeData={this.state.vendorTypeData}
              doNotIssueDropdownOptions={this.state.doNotIssueDropdownOptions} 
              handleCancel={this.handleCancel}
              selectedItem={this.state.selectedVendor}
            /> : ''}
          {this.state.loading
            ?
            <CircularProgress />
            : <div >
              <Table style={{ maxHeight: '410px', overflowY: 'scroll' }}
                className="table-layout"
                columns={columns}
                components={components}
                dataSource={filteredData}
                rowClassName={(record) => this.state.selectedItemKey == record.key ? 'data-row selected' : 'data-row'}
                bordered={true}
                size={"small"}
                // onRowClick={(record, index, event) => this.handleEditModal(record)}
                onRowDoubleClick={(record, index, event) => this.handleEditModal(record)}
                onChange={this.handleTableChange}
                loading={this.state.loading}

                pagination={false}
              // scroll={{y:window.innerHeight-301}}
              />
              <Pagination
                pageSizeOptions={['10', '25', '50', '100']}
                pageSize={this.state.pageSize}
                style={{ marginTop: '10px', marginBottom: '10px' }}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                showSizeChanger onChange={(a, b) => this.onShowSizeChange(a, b)} onShowSizeChange={(a, b) => this.onShowSizeChange(a, b)} defaultCurrent={1} total={this.state.totalRowsInTable} />
            </div>
          }
        </div>}
      </React.Fragment>


    );
  }
}

const mapStateToProps = (state) => {
  return {
    vendors: state.vendors.vendors,
    entityTypeData: state.vendors.entityTypes,
    vendorTypeData: state.vendors.vendorTypes,
    doNotIssueDropdownOptions: state.vendors.doNotIssueDeopdownValues,
    reload: state.vendors.reload,
    activeInactive: state.ReportData.activeInactive
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    Vendors,
    searchVendor,
    vendorTypes,
    entitytypes,
    doNotIssueDeopdownValues,
    GetVendors,
    Post_Vendor_UpdateVendorStatus
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(VendorPage);
